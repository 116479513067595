import React from "react"
import { useLayoutContext } from "~layout/contexts/layout"
import Page from "~common/components/Page"
import { Box } from "~common/components/Primitives"
import ProductDetails from "~shop/components/ProductDetails"

const ShopPage = () => {
  const { headerHeight, availableHeight } = useLayoutContext()

  return (
    <Page
      seo={{
        title: "The Copernican Revolution",
        description:
          "A special designed duo of posters to celebrate the Copernican paradigm shift from the Geocentric to the Heliocentric frame of reference.",
      }}
    >
      <Page.Body
        sx={{
          mt: headerHeight,
          minHeight: availableHeight,
        }}
      >
        <ProductDetails initialProductId="copernican_revolution">
          <ProductDetails.Heading>
            <ProductDetails.Title />
          </ProductDetails.Heading>
          <ProductDetails.Gallery>
            <ProductDetails.Image />
            <ProductDetails.ImageSelector />
          </ProductDetails.Gallery>
          <ProductDetails.Info>
            <ProductDetails.Title sx={{ display: ["none", "none", "block"] }} />
            <ProductDetails.Description sx={{ mt: [4] }} />
            <Box as="ul" sx={{ mt: [4] }}>
              <ProductDetails.Feature icon="shipping">
                FREE WORLDWIDE SHIPPING (7-14 days)
              </ProductDetails.Feature>
              <ProductDetails.Feature icon="ruler">
                <ProductDetails.Size />
              </ProductDetails.Feature>
              <ProductDetails.Feature icon="paper">
                Printed on Enhanced Matte Paper
              </ProductDetails.Feature>
              <ProductDetails.Feature icon="eco">
                Toxin-free, Biodegradable, Water-based Ink
              </ProductDetails.Feature>
            </Box>
            <Box as="ul" sx={{ mt: [4] }}>
              <ProductDetails.Note>no frame included</ProductDetails.Note>
            </Box>
            <ProductDetails.PriceTag sx={{ mt: [4] }} />
            <ProductDetails.Cta
              sx={{ mt: [4, 5], alignSelf: ["center", "center", "flex-start"] }}
            />
          </ProductDetails.Info>
        </ProductDetails>
      </Page.Body>
    </Page>
  )
}

export default ShopPage
